import React, { FC } from 'react';
import {
  useErrorMonitor,
  usePanorama,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { Button } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const panorama = usePanorama();
  const errorMonitor = useErrorMonitor();

  const reportHandledError = ({
    usingPanorama,
    usingErrorMonitor,
  }: {
    usingPanorama?: boolean;
    usingErrorMonitor?: boolean;
  }) => {
    if (usingErrorMonitor) {
      errorMonitor.captureException(Error('My Handled ErrorMonitor Error'));
    }

    if (usingPanorama) {
      panorama.errorMonitor().reportError(Error('My Handled Panorama Error'));
    }
  };

  const throwUnhandledError = () => {
    throw new Error('My Unhandled Error');
  };

  const reportTransaction = () => {
    panorama.transaction('My Panorama Transaction').start();
    setTimeout(
      () => panorama.transaction('My Panorama Transaction').finish(),
      1000,
    );
  };

  return (
    <div className={st(classes.root, {})} data-hook="MyPage-wrapper">
      <div
        style={{
          display: 'flex',
          width: '300px',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Button className={classes.mainButton} onClick={reportTransaction}>
          Report Panorama transaction
        </Button>

        <Button
          className={classes.mainButton}
          onClick={() => reportHandledError({ usingPanorama: true })}
        >
          Report Panorama handled error
        </Button>

        <Button
          className={classes.mainButton}
          onClick={() => reportHandledError({ usingErrorMonitor: true })}
        >
          Report ErrorMonitor handled error
        </Button>

        <Button className={classes.mainButton} onClick={throwUnhandledError}>
          Throw unhandled error
        </Button>
      </div>
    </div>
  );
};

export default Widget;
